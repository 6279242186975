<template>
  <!-- <vs-popup
    title="Update status"
    :active.sync="activePrompt"
    button-close-hidden
  >
    <div class="flex flex-col gap-10 items-center">
      <p class="font-bold">
        Are you sure want to request for approval for
        <span class="font-bold">{{ selectedData.code }}</span>
      </p>
      <div class="flex flex-col gap-3">
        <vs-radio v-model="status" :vs-value="1"><h4>Approve</h4></vs-radio>
        <vs-radio v-model="status" :vs-value="3" color="success"
          ><h4>Confirm</h4></vs-radio
        >
        <vs-radio v-model="status" :vs-value="2" color="danger"
          ><h4>Reject</h4></vs-radio
        >
      </div>
      <div class="flex flex-row gap-2">
        <vs-button @click="updateStatus">Update</vs-button>
        <vs-button @click="closePrompt" color="danger">Close</vs-button>
      </div>
    </div>
  </vs-popup> -->
  <vs-prompt
    @cancel="closePrompt"
    @accept="updateStatus"
    @close="closePrompt"
    :active.sync="activePrompt"
    :title="isApprove ? 'Approval' : 'Rejection'"
    :color="isApprove ? 'primary' : 'danger'"
  >
    <div class="con-exemple-prompt">
      Are you sure want to
      <span>{{ isApprove ? "approve" : "reject" }}</span> approval for
      <span class="font-bold">{{ selectedData.sold_to_code }}</span
      >?
    </div>
  </vs-prompt>
</template>

<script>
export default {
  props: {
    isActive: Boolean,
    isApprove: Boolean,
    selectedData: Object,
  },
  mounted() {
    this.activePrompt = this.isActive;
  },
  watch: {
    activePrompt() {
      if (this.activePrompt) {
        return;
      }
      this.closePrompt();
    },
    isActive() {
      this.activePrompt = this.isActive;
    },
    selectedData() {
      console.log(this.selectedData);
    },
  },
  data() {
    return {
      activePrompt: false,
    };
  },
  methods: {
    closePrompt() {
      this.$emit("closePrompt");
    },
    updateStatus() {
      this.$vs.loading();
      this.$http
        .put(`/api/v1/customer-invoice-exchange/${this.selectedData.ID}`, {
          status: this.isApprove ? 2 : 3, // 2: approve, 3: reject
        })
        .then((r) => {
          if (r.code > 299) {
            this.$vs.loading.close();
            this.$vs.notify({
              position: "top-right",
              title: this.isApprove ? "Approval" : "Rejection",
              text: r.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
            });
          } else if (r.code == 200) {
            this.$vs.notify({
              position: "top-right",
              title: this.isApprove ? "Approval" : "Rejection",
              text: this.isApprove ? "Approval success" : "Rejection success",
              color: "success",
              iconPack: "feather",
              icon: "icon-check-circle",
            });
            this.$emit("reloadDataFromChild");
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
          this.closePrompt();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            position: "top-right",
            title: this.isApprove ? "Approval" : "Rejection",
            text: err.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        });
    },
  },
};
</script>
