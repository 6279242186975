<template>
  <vx-card title="Customer Invoice Exchange">
    <vs-tabs>
      <vs-tab label="Waiting Approval">
        <div class="con-tab-ejemplo">
          <waiting />
        </div>
      </vs-tab>
      <vs-tab label="Approved">
        <div class="con-tab-ejemplo">
          <approve />
        </div>
      </vs-tab>
      <vs-tab label="Rejected">
        <div class="con-tab-ejemplo"><reject /></div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import approve from "./approved/index.vue";
import reject from "./rejected/index.vue";
import waiting from "./waiting/index.vue";
export default {
  components: {
    approve,
    reject,
    waiting,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
